import { useCallback } from "react";

const useEventHandler = (handler) => useCallback((...args) => handler && handler(...args), [handler]);

const useSocketEventHandlers = ({
  orderId,
  onRefresh,
  refreshExpirationToken,
  onRefreshWithoutResetingIsPay,
  onOtherPaid,
  onPaymentExpired,
  onOtherTryingToPay,
  onOrderClosed,
  refreshReservations,
  orderExpired,
  onMenuItemQuantityChanged,
  onGuestsHaveSelectedMoreQuantityThanItemHas,
  onMenuItemIsOutOfStock,
  onDeleteItemsDueToMenuTimeChange,
  onStartBookedOrder,
  onUserInfoChanged,
  onGuestsPresenceChanged,
  onUserLoyaltyStepChange,
}) => {
  const handleOrderModified = useEventHandler(() => {
    onRefresh && onRefresh(orderId);
    refreshExpirationToken && refreshExpirationToken(orderId);
  });

  const handleOrderModifiedWithoutReset = useEventHandler(() => {
    onRefreshWithoutResetingIsPay && onRefreshWithoutResetingIsPay(orderId);
  });

  const handlePaymentMade = useEventHandler((data) => {
    onOtherPaid && onOtherPaid(data);
    onRefreshWithoutResetingIsPay && onRefreshWithoutResetingIsPay(orderId);
  });

  const handlePaymentExpired = useEventHandler((data) => {
    onPaymentExpired && onPaymentExpired(data);
  });

  const handlePayForOthers = useEventHandler((data) => {
    onOtherTryingToPay && onOtherTryingToPay(data);
  });

  const handleOrderClosed = useEventHandler(() => {
    onOrderClosed && onOrderClosed(orderId);
    onRefresh && onRefresh(orderId);
  });

  const handleRefreshExpirationToken = useEventHandler(() => {
    refreshExpirationToken && refreshExpirationToken(orderId);
  });

  const handleOrderExpired = useEventHandler(() => {
    refreshReservations && refreshReservations();
    orderExpired && orderExpired();
  });

  const handleMenuItemQuantityChanged = useEventHandler((data) => {
    onMenuItemQuantityChanged && onMenuItemQuantityChanged(data);
    onRefresh && onRefresh(orderId);
  });

  const handleGuestsHaveSelectedMoreQuantity = useEventHandler((data) => {
    onGuestsHaveSelectedMoreQuantityThanItemHas && onGuestsHaveSelectedMoreQuantityThanItemHas(data);
  });

  const handleMenuItemOutOfStock = useEventHandler((itemsNames) => {
    onMenuItemIsOutOfStock && onMenuItemIsOutOfStock(itemsNames);
    onRefresh && onRefresh(orderId);
  });

  const handleDeleteItemsDueToMenuTimeChange = useEventHandler((orderItems) => {
    onDeleteItemsDueToMenuTimeChange && onDeleteItemsDueToMenuTimeChange(orderItems);
    onRefresh && onRefresh(orderId);
  });

  const handleStartBookedOrder = useEventHandler(() => {
    onStartBookedOrder && onStartBookedOrder(orderId);
  });

  const handleUserInfoChanged = useEventHandler((data) => {
    onUserInfoChanged && onUserInfoChanged(data);
  });

  const handleGuestsPresenceChanged = useEventHandler(() => {
    onGuestsPresenceChanged && onGuestsPresenceChanged(orderId);
  });

  const handleUserLoyaltyStepChange = useEventHandler((data) => {
    onUserLoyaltyStepChange && onUserLoyaltyStepChange(data);
  });

  return {
    handleOrderModified,
    handleOrderModifiedWithoutReset,
    handlePaymentMade,
    handlePaymentExpired,
    handlePayForOthers,
    handleOrderClosed,
    handleRefreshExpirationToken,
    handleOrderExpired,
    handleMenuItemQuantityChanged,
    handleGuestsHaveSelectedMoreQuantity,
    handleMenuItemOutOfStock,
    handleStartBookedOrder,
    handleUserInfoChanged,
    handleGuestsPresenceChanged,
    handleDeleteItemsDueToMenuTimeChange,
    handleUserLoyaltyStepChange,
  };
};

export default useSocketEventHandlers;
