import { debounce } from "lodash";
import isEmpty from "lodash.isempty";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import hexColors from "../../constants/hexColors";
import { getReservationDetails, setReservationDetails } from "../../pages/book/services/actions";
import { initialReservationDetails } from "../../pages/book/services/reducers";
import {
  completeOrder,
  getCheckoutInfo,
  getLastRoundOrders,
  getOrdersNoRounds,
  getOrdersRounds,
  sendToKitchen,
  setHasNewOrder,
} from "../../pages/order/services/actions";
import {
  getCurrentReservation,
  setOtherTryingToPay,
  setRefreshCheckout,
} from "../../pages/reservations/services/actions";
import { setConnectSocketAfterIdleTimeout } from "../../store/actions/feedbackActions";
import { setIsPay } from "../../store/actions/usersActions";
import { genHexIndex } from "../../utils/genHexIndex";
import "../reservation/Reservation.css";
import "./OrdersRounds.css";
import { ARCHIVED_USER_COLOR } from "./components/Round";
import RoundCompletion from "./components/RoundCompletion";
import SendToKitchenModal from "./components/SendToKitchenModal";
import UserOrders from "./components/UserOrders";
import "./components/styles/OrdersCheckout.css";
import "./components/styles/Round.css";
import "./components/styles/UserOrderItem.css";
import "./components/styles/UserOrders.css";

function OrdersRounds({ orderId, canOrder = true, emitFriendSelected }) {
  const {
    auth: {
      user: { id, is_anonymous },
    },
    user: { isPay, user }, // updateUserReceipt
    orders: { ordersRounds, lastRoundOrders, checkoutInfo, ordersNoRounds, hasNewOrder },
    booking: { currentOrder, refreshCheckout, otherTryingToPay, reservationDetails },
    feedback: { connectSocketAfterIdleTimeout },
    versionConfig: { show_table_number },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const history = useHistory();

  const [payForUsers, setPayForUsers] = useState([]);
  const [sendToKitchenModal, setSendToKitchenModal] = useState(false);
  const [orderUserIds, setOrderUserIds] = useState([]);
  const [isCheckBoxClicked, setIsCheckBoxClicked] = useState(false);
  const [isProceedClicked, setIsProceedClicked] = useState(true);
  const [showReceiptButton, setShowReceiptButton] = useState(false);
  const [avatarColors, setAvatarColors] = useState([]);
  const [waitingForAllDataToCome, setWaitingForAllDataToCome] = useState(true);
  const [isActiveReservation, setIsActiveReservation] = useState(true);

  const [isAccordionOpened, setIsAccordionOpened] = useState(false);
  const [ordersData, setOrdersData] = useState([]);
  // const menuStepQueryParam = getQueryParam(queryParamsEnum.step, localStorageHelper.getItem(queryParamsEnum.step))

  if (waitingForAllDataToCome === true) {
    if (typeof checkoutInfo.totalTip !== "number") {
      setWaitingForAllDataToCome(false);
    }
  }

  // useEffect(() => {
  //   ordersNoRounds.map((zapis, idx) => {
  //     if (zapis.user_id === user.user_id) {
  //       setIsAccordionOpened(true)
  //     }
  //   })
  // }, [ordersNoRounds, isAccordionOpened])

  useEffect(() => {
    setIsCheckBoxClicked(true);
  }, []);

  useEffect(() => {
    reservationDetails.reservation?.is_closed && setIsActiveReservation(false);
  }, [reservationDetails, isActiveReservation]);

  useEffect(() => {
    if (orderId) {
      dispatch(getOrdersRounds(orderId));
      dispatch(getOrdersNoRounds(orderId));
    }
  }, [dispatch, orderId]);

  useEffect(() => {
    if (orderId && !isEmpty(ordersNoRounds)) {
      setOrdersData(ordersNoRounds);
    }
    let colors = [];
    if (Object.values(ordersData).length !== avatarColors.length) {
      Object.values(ordersData).forEach(() => {
        let hexIndex = genHexIndex(colors);
        colors.push(hexColors[hexIndex]);
      });
      setAvatarColors(colors);
    }

    if (ordersData) {
      ordersData.map((record, idx) => {
        if (record.user_id === user.user_id) {
          setIsAccordionOpened(true);
        }
      });
    }

    // return () => setOrdersData([])
  }, [avatarColors, ordersNoRounds, isAccordionOpened, ordersData, user, orderId]);

  const onCompleteOrderClick = useCallback(
    async ({ instance, spreedlyInformation }) => {
      if (!payForUsers.includes(id)) {
        payForUsers.push(id);
      }

      dispatch(
        completeOrder(
          {
            orderId,
            users: payForUsers,
            spreedlyInformation,
            is_anonymous,
          },
          history,
        ),
      ).then(() => {
        onPaymentCheckboxChange({ userId: id, isProceed: true, isEdit: true });
        dispatch(setIsPay(false));
        // dispatch(setIsProceededToCheckoutSelection(false))
      });
    },
    [orderId, payForUsers, dispatch, is_anonymous, history, id],
  );

  const updateCheckoutInfo = useCallback(
    debounce(() => {
      if (isProceedClicked) {
        const usersToPayFor = isPay ? (payForUsers.length ? payForUsers : [id]) : [id];
        dispatch(getCheckoutInfo(orderId, usersToPayFor));
        dispatch(setRefreshCheckout(false));
      }
    }, 1000),

    [dispatch, orderId, id, isProceedClicked, isPay, payForUsers],
  );

  useEffect(() => {
    if (payForUsers && isCheckBoxClicked) {
      if (isPay) {
        emitFriendSelected(orderId, payForUsers, id);
      } else {
        emitFriendSelected(orderId, [], id);
      }
      setIsCheckBoxClicked(false);
    } else if (connectSocketAfterIdleTimeout) {
      emitFriendSelected(orderId, [], id);
      dispatch(setConnectSocketAfterIdleTimeout(false));
    } else {
      if (!isPay) {
        emitFriendSelected(orderId, [], id);
      }
    }
  }, [dispatch, emitFriendSelected, orderId, payForUsers, id, isCheckBoxClicked, isPay, connectSocketAfterIdleTimeout]);

  useEffect(() => {
    if (refreshCheckout) {
      updateCheckoutInfo();
    }
  }, [refreshCheckout, updateCheckoutInfo, dispatch]);

  useEffect(() => {
    setPayForUsers((arr) => {
      let val = [...arr];
      const currentIdIndex = val.findIndex((el) => el === id);
      if (currentIdIndex < 0) {
        if (!otherTryingToPay) val.push(id);
      }
      if (otherTryingToPay) {
        val.splice(currentIdIndex, 1);
      }
      return val;
    });
  }, [id, otherTryingToPay]);

  useEffect(() => {
    updateCheckoutInfo();
  }, []);

  useEffect(() => {
    let currentRound = ordersRounds.find((round) => !round.round);
    if (currentRound !== undefined) {
      currentRound.users = currentRound.users.filter((user) => user.is_present && user.orders.length);
      if (currentRound.users.length) {
        dispatch(setHasNewOrder(true));
      } else {
        dispatch(setHasNewOrder(false));
      }
    } else {
      dispatch(setHasNewOrder(false));
    }
  }, [dispatch, payForUsers, ordersRounds, id]);

  useEffect(() => {
    let currentRound = ordersRounds.find((round) => !round.round);
    if (currentRound) {
      let currentUser = currentRound.users.find((user) => +user.user_id === +id);
      if (currentUser) {
        dispatch(setOtherTryingToPay(false));
      }
    }
  }, [ordersRounds, id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setHasNewOrder(false));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getReservationDetails(+orderId, history));
    return () => {
      dispatch(setReservationDetails(initialReservationDetails));
    };
  }, [dispatch, orderId, history]);

  const onPaymentCheckboxChange = ({ userId, isProceed, isEdit }) => {
    setIsProceedClicked(isProceed);
    if (isEdit) {
      setPayForUsers([]);
      setIsCheckBoxClicked(true);
      return;
    }
    if (isProceed) {
      setIsCheckBoxClicked(true);
    } else {
      setPayForUsers((arr) => {
        let val = [...arr];
        let index = val.findIndex((el) => +el === +userId);
        if (index < 0) val.push(+userId);
        else val.splice(index, 1);
        return val;
      });
    }
  };

  const onSendToKitchen = async () => {
    dispatch(sendToKitchen(orderId, payForUsers));

    setSendToKitchenModal(false);
  };

  const onRefreshVoidedOrders = (tip) => {
    dispatch(getCheckoutInfo(orderId, payForUsers, tip));
  };

  const onSendToKitchenClickHandler = () => {
    // allFriendsHaveOrdered(ordersRounds)
    //   ? onSendToKitchen()
    //   :
    setSendToKitchenModal(true);
  };

  useEffect(() => {
    if (ordersRounds.length > 0) {
      const userIds = [];

      ordersRounds.forEach((round) => {
        round.users.forEach((user) => {
          const isUserToAdd = user.orders.length === 0 || user.orders.some((order) => !order.paid);

          if (isUserToAdd) {
            user = {
              userId: user.user_id,
              userName: user.user,
              orders: user.orders,
            };
          }

          if (!userIds.some((userId) => userId.userId === user.userId) && isUserToAdd)
            if (user.orders.length !== 0) userIds.push(user);
        });
      });
      setOrderUserIds(userIds.filter((user) => user.userId !== id));
    }

    if (!currentOrder) dispatch(getCurrentReservation());
    else if (!lastRoundOrders) dispatch(getLastRoundOrders(currentOrder.orderId));
  }, [ordersRounds, id, dispatch, currentOrder?.orderId]);

  useEffect(() => {
    // check if any user's order is paid by himself
    // if there is none, hide the receipt button
    // all orders have been paid by other users
    if (currentOrder?.has_receipt) {
      setShowReceiptButton(true);
    }
  }, [ordersRounds, id, showReceiptButton]);

  // useEffect(() => {
  //   if (orderId && (!receiptURL || updateUserReceipt)) {
  //     dispatch(getReceipt(orderId, setReceiptURL))
  //     dispatch(setUpdateUserReceipt(false))
  //   }

  // }, [dispatch, orderId, updateUserReceipt, receiptURL])

  return (
    <>
      {!waitingForAllDataToCome && (
        <>
          {sendToKitchenModal ? (
            <SendToKitchenModal
              show={sendToKitchenModal}
              close={() => setSendToKitchenModal(false)}
              onSend={onSendToKitchen}
            />
          ) : null}

          <div className="orders-rounds-wrapper">
            {show_table_number !== null && show_table_number ? (
              <h4 style={{ textAlign: "center", fontFamily: "Cormorant", marginBottom: "1rem" }}>
                Orders on table {currentOrder?.table_number}
              </h4>
            ) : null}
            {/* <div className='checkout-button' style={{ display: "flex", justifyContent: "center" }}>
              <button onClick={() => history.push(`${order_page_path.replace(':orderId', currentOrder?.orderId)}${menuStepQueryParam}`)} style={{ margin: "0.5rem 0 1rem" }} className="btn btn-primary">
                Add items from Menu
              </button>
            </div> */}

            {/* NO ROUNDS! */}

            {ordersData?.map((user, idx) => {
              const userDetails =
                !isEmpty(reservationDetails.reservationGuests) && Array.isArray(reservationDetails.reservationGuests)
                  ? reservationDetails.reservationGuests.find((guest) => guest.user_id === user.user_id)
                  : null;
              const isPresent = userDetails?.is_present ?? true;
              return (
                <UserOrders
                  key={user.user_id ?? idx}
                  orderPosition={idx}
                  is_archived={user.is_archived}
                  canPay={hasNewOrder}
                  canOrder={canOrder}
                  onPaymentCheckboxChange={() => onPaymentCheckboxChange({ userId: user.user_id })}
                  isPaymentChecked={!!payForUsers.find((userId) => +userId === +user.user_id)}
                  areOwnOrders={id === +user.user_id}
                  avatarBackground={user.is_archived ? ARCHIVED_USER_COLOR : avatarColors[idx]}
                  avatarColors={avatarColors}
                  userDisplayName={id === +user.user_id ? user.user : user.user}
                  userStatus={user.user_order_status && user.user_order_status}
                  firstLetter={
                    user.is_archived ? "?" : user.user.charAt(0).toUpperCase()
                    // user.is_anonymous
                    //   ? `${user.user.charAt(0).toUpperCase()}${user.user.charAt(user.user.length - 1).toUpperCase()}`
                    //   : user.user.charAt(0).toUpperCase()
                  }
                  orderId={orderId}
                  {...user}
                  user={user}
                  isActiveReservation={isActiveReservation}
                  currentUserId={user.user_id}
                  isAccordionOpened={isAccordionOpened}
                  hasTableId={true}
                  isPresent={isPresent}
                  da={"da"}
                />
              );
            })}
            <RoundCompletion
              checkoutInfo={checkoutInfo}
              onCompleteOrderClick={onCompleteOrderClick}
              onSendToKitchen={onSendToKitchenClickHandler}
              otherTryingToPay={otherTryingToPay}
              payForUsers={payForUsers}
              hasNewOrder={hasNewOrder}
              onPaymentCheckboxChange={onPaymentCheckboxChange}
              orderUserIds={orderUserIds}
              onRefreshVoidedOrders={onRefreshVoidedOrders}
            />
          </div>
        </>
      )}
    </>
  );
}

export default OrdersRounds;
