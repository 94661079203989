import axios from "axios";
import isEmpty from "lodash.isempty";
import React from "react";
import {
  SET_APPLIED_LOYALTY_POINTS,
  SET_BOOKED_RESERVATION_RETURN,
  SET_CHECKOUT_INFO,
  SET_CLEAR_RESERVATION_ORDERS,
  SET_FEEDBACK,
  SET_HAS_NEW_ORDER,
  SET_LAST_ROUND_ORDERS,
  SET_LOYALTY_ON_CURRENT_RESTAURANT,
  SET_NEW_ORDER_DETAILS,
  SET_ORDERS,
  SET_ORDERS_NO_ROUNDS,
  SET_ORDERS_ROUNDS,
  SET_ORDER_NEXT_ROUTE,
  SET_PAYMENT_METHODS_FOR_USER,
  SET_RESERVATION_GUESTS,
  SET_SHOW_ADD_ITEM_MODAL,
  SET_TABLE_NUMBER,
  SET_USERS_LOYALTY_PROGRAM_ON_ORDER,
  SET_USER_LOYALTY_PROGRAM,
  SET_USER_LOYALTY_PROGRAM_ACCUMULATED_POINTS,
  SET_USER_RATING,
  SET_USER_TRANSACTIONS_LOYALTY_PROGRAM,
  SET_USER_TRANSACTION_LOYALTY_PROGRAM,
  SET_LOYALTY_PROCESS,
  SET_USER_LOYALTY_STEP,
  SET_ALL_GUEST_SELECTED_LOYALTY_OPTION,
  SET_CHOOSE_IF_GUEST_WANT_TO_USE_CREDITS_MODAL,
} from "../../../config/actionTypes";
import {
  add_to_order_api,
  complete_orders_api,
  create_reservation_api,
  edit_feedback_api,
  edit_rating_api,
  get_checkout_info_api,
  get_last_round_orders_api,
  get_orders_api,
  get_orders_no_rounds_api,
  get_orders_rounds_api,
  get_payment_methods_api,
  item_info_api,
  order_item_api,
  orders_loyalty_program_applied_api,
  orders_loyalty_program_pending_api,
  payment_method_api,
  send_to_kitchen_api,
  user_loyalty_program,
  user_transaction_loyalty_program,
  users_loyalty_program_on_order,
} from "../../../config/api_endpoints";
import {
  payment_done_anon_user_modal,
  payment_done_modal,
  sent_to_kitchen_modal,
  sent_to_kitchen_modal_out_of_stock_items,
} from "../../../config/modal_paths";
import { home_path, order_page_path, past_order_page_path, reservations_path_now } from "../../../config/pages_paths";
import { createFormattedDateString } from "../../../config/timeDate";
import { setErrors } from "../../../store/actions/errorsActions";
import {
  setActiveModal,
  setAnchorMenu,
  setIsForWhomToPayConfirmed,
  setLoader,
  setMenuItemQuantityChangedModal,
  setToastMessage,
} from "../../../store/actions/feedbackActions";
import { setIsPay, setIsProceededToCheckoutSelection, setUpdateUserReceipt } from "../../../store/actions/usersActions";
import {
  getCurrentReservation,
  getReceipt,
  getReservations,
  setRefreshCheckout,
  setTimerExpirationTime,
} from "../../reservations/services/actions";
import { setRestaurant } from "../../restaurant/services/actions";
import { setActiveMenuSectionItemsWithRoundForUserApproved } from "../../menu/services/actions";
import localStorageHelper from "../../../utils/localStorageHelper";
import queryParamsEnum from "../../../enums/queryParamsEnum";
import { clearRestaurantReservation } from "../../../store/actions/restaurantsActions";

export const getOrders = (orderId, ignoreIsPay) => async (dispatch) => {
  try {
    if (orderId === undefined || orderId === null || isNaN(orderId)) return;
    dispatch(setLoader(true));
    const result = await axios.get(get_orders_api.replace(":orderId", orderId));
    dispatch(setOrders(result.data.orders));
    !ignoreIsPay && dispatch(setIsPay(false));
    !ignoreIsPay && dispatch(setIsProceededToCheckoutSelection(false));
  } catch (err) {
    dispatch(setErrors(err, () => getOrders(orderId)));
  } finally {
    dispatch(setLoader(false));
  }
};

export const addItemToOrder = (orderId, data, history) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const {
      data: { userOrders, expirationTime },
    } = await axios.post(add_to_order_api.replace(":orderId", orderId), data);
    dispatch(getOrdersRounds(orderId));
    dispatch(getOrdersNoRounds(orderId));
    dispatch(setOrders(userOrders));
    // dispatch(setTimerExpirationTime(expirationTime))
    return { userOrders, expirationTime };
  } catch (err) {
    // history.push(home_path)
    dispatch(setErrors(err, () => addItemToOrder(orderId, data, history)));
  } finally {
    dispatch(setLoader(false));
  }
};

export const setOrders = (orders) => (dispatch) => {
  dispatch({ type: SET_ORDERS, payload: orders });
};

export const editOrderItem = (roundItemId, orderId, data) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const {
      data: { expirationTime },
    } = await axios.put(order_item_api.replace(":roundItemId", roundItemId), data);
    dispatch(getOrders(orderId));
    dispatch(getOrdersRounds(orderId));
    dispatch(getOrdersNoRounds(orderId));
    dispatch(getLastRoundOrders(orderId));
    dispatch(setTimerExpirationTime(expirationTime));
    return expirationTime;
  } catch (err) {
    dispatch(setErrors(err, () => editOrderItem(roundItemId, orderId, data)));
  } finally {
    dispatch(setLoader(false));
  }
};

export const removeOrderItem = (roundItemId, orderId) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const {
      data: { expirationTime },
    } = await axios.delete(order_item_api.replace(":roundItemId", roundItemId));
    dispatch(getOrders(orderId));
    dispatch(getOrdersRounds(orderId));
    dispatch(getOrdersNoRounds(orderId));
    dispatch(setTimerExpirationTime(expirationTime));
    dispatch(getLastRoundOrders(orderId));
    return expirationTime;
  } catch (err) {
    dispatch(setErrors(err, () => removeOrderItem(roundItemId, orderId)));
  } finally {
    dispatch(setLoader(false));
  }
};

export const getOrdersRounds = (order_id) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const result = await axios.get(get_orders_rounds_api.replace(":orderId", order_id));
    dispatch(setOrdersRounds(result.data.orders));
    dispatch(setRestaurant(result.data.restaurant));
    dispatch(setReservationGuests(result.data.guests));
  } catch (err) {
    dispatch(setErrors(err, () => getOrdersRounds(order_id)));
  } finally {
    dispatch(setLoader(false));
  }
};

export const setOrdersNoRounds = (payload) => (dispatch) => {
  dispatch({ type: SET_ORDERS_NO_ROUNDS, payload });
};

export const getOrdersNoRounds = (orderId) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const result = await axios.get(get_orders_no_rounds_api.replace(":orderId", orderId));
    dispatch(setOrdersNoRounds(result.data.orders));
  } catch (err) {
    dispatch(setErrors(err, () => getOrdersNoRounds(orderId)));
  } finally {
    dispatch(setLoader(false));
  }
};

export const setOrdersRounds = (payload) => (dispatch) => {
  dispatch({ type: SET_ORDERS_ROUNDS, payload });
};

export const setNewNameForAnonGuest = (userId, userName) => async (dispatch, getState) => {
  try {
    const {
      orders: { ordersNoRounds },
    } = getState();

    const newOrdersNoRounds = ordersNoRounds.map((order) => {
      if (order.user_id === userId) {
        order.user = userName;
      }

      return order;
    });

    dispatch(setOrdersNoRounds(newOrdersNoRounds));
  } catch (error) {
    dispatch(setErrors(error, () => setNewNameForAnonGuest(userId, userName)));
  }
};

export const getLastRoundOrders = (orderId) => async (dispatch) => {
  try {
    if (orderId === undefined || orderId === null || isNaN(orderId)) return;
    dispatch(setLoader(true));
    const result = await axios.get(get_last_round_orders_api.replace(":orderId", orderId));
    dispatch(setLastRoundOrders(result.data.lastRoundOrders));
  } catch (err) {
    dispatch(setErrors(err, () => getLastRoundOrders(orderId)));
  } finally {
    dispatch(setLoader(false));
  }
};

export const setLastRoundOrders = (lastRoundOrders) => (dispatch) => {
  dispatch({ type: SET_LAST_ROUND_ORDERS, payload: lastRoundOrders });
};

export const setReservationGuests = (guests) => (dispatch) => {
  dispatch({ type: SET_RESERVATION_GUESTS, payload: guests });
};

export const completeOrder = (data, history) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    dispatch(setAnchorMenu(false));
    const result = await axios.post(complete_orders_api, data);
    if (!isEmpty(data.spreedlyInformation))
      data.is_anonymous
        ? dispatch(setActiveModal(payment_done_anon_user_modal))
        : dispatch(setActiveModal(payment_done_modal));

    const { userRating } = result.data;
    dispatch(
      setRating({
        orderId: data.orderId,
        ratingStars: userRating.rating_stars,
        ratingDescription: userRating.rating_description,
      }),
    );

    // dispatch(getOrdersRounds(data.order_id))
    // dispatch(getCheckoutInfo(data.order_id, data.users))
    dispatch(setUpdateUserReceipt(true));
    dispatch(setIsForWhomToPayConfirmed(false));
    // dispatch(getReservations())
    dispatch(getCurrentReservation());

    // Clear Guest Reservation State
    dispatch(clearRestaurantReservation());
    dispatch(setClearReservationOrders());

    localStorageHelper.exists(queryParamsEnum.step) && localStorageHelper.removeItem(queryParamsEnum.step);
  } catch (err) {
    dispatch(
      setToastMessage({
        message: err.response.data.message || "Payment Error occured",
        severity: "error",
        timer: 10000,
      }),
    );
    dispatch(setErrors(err, () => completeOrder(data, history)));
  } finally {
    dispatch(setLoader(false));
  }
};

export const sendToKitchen = (order_id, users = []) => async (dispatch, getState) => {
  try {
    dispatch(setLoader(true));
    const {
      data: {
        // expirationTime,
        orders,
      },
    } = await axios.post(send_to_kitchen_api, { order_id });

    const {
      feedback: { showMenuItemQuantityChangedModal },
    } = getState();

    if (orders.notDispatchedOrdersFormatedData !== undefined && orders.notDispatchedOrdersFormatedData.length > 0) {
      if (!orders.isThereOutOfStockItemOnToastForUser) {
        dispatch(setActiveModal(sent_to_kitchen_modal));
      } else {
        dispatch(setActiveModal(sent_to_kitchen_modal_out_of_stock_items, orders.outOfStockItemsNamesForUser));
      }

      // dispatch(getOrdersRounds(order_id))
      // dispatch(getCheckoutInfo(order_id, users))
    }

    if (orders.length === 0 && !showMenuItemQuantityChangedModal.show) {
      const modalMessage = {
        heading: "We sincerely apologise",
        message: (
          <>
            <span>Oops, we are currently out of stock for the chosen menu items.</span>

            <span span style={{ marginTop: ".5rem", marginBottom: ".5rem" }}>
              Please try another tasty option instead.
            </span>
          </>
        ),
      };

      dispatch(setMenuItemQuantityChangedModal({ show: true, message: modalMessage }));
    }

    return { orders };
  } catch (err) {
    dispatch(setErrors(err, () => sendToKitchen(order_id, users)));
  } finally {
    dispatch(setLoader(false));
  }
};

export const setOrderNextRoute = (payload) => (dispatch) => {
  dispatch({ type: SET_ORDER_NEXT_ROUTE, payload });
};

export const getCheckoutInfo = (orderId, userIds) => async (dispatch, getState) => {
  const {
    feedback: { toast },
  } = getState();

  try {
    if (orderId === null || orderId === undefined || isNaN(orderId)) return;
    // dispatch(setLoader(true))
    const {
      data: { checkoutInfo },
    } = await axios.post(get_checkout_info_api.replace(":orderId", orderId), { userIds });
    dispatch(setCheckoutInfo(checkoutInfo));
    if (checkoutInfo?.unrecreatedExternalItems) {
      dispatch(
        setToastMessage({
          severity: "error",
          message: "There are unpaid additional orders",
          unrecreatedExternalItems: checkoutInfo.unrecreatedExternalItems,
          autoHide: false,
        }),
      );
    } else {
      if ((toast.show || !isEmpty(toast.message)) && !isEmpty(toast.unrecreatedExternalItems)) {
        dispatch(setToastMessage({ show: false, severity: "", message: "", autoHide: true }));
      }
    }
  } catch (err) {
    dispatch(setErrors(err, () => getCheckoutInfo(orderId, userIds)));
  }
  // finally{
  //   dispatch(setLoader(false))
  // }
};

export const setCheckoutInfo = (payload) => ({ type: SET_CHECKOUT_INFO, payload });

export const setNewOrderDetails = (payload) => ({
  type: SET_NEW_ORDER_DETAILS,
  payload,
});

export const createReservation = (reservationData, orderData, history) => async (dispatch, getState) => {
  try {
    dispatch(setLoader(true));
    const {
      booking: { currentOrder },
    } = getState();
    if (isEmpty(currentOrder)) {
      const {
        data: {
          // orderId,
          expirationTime,
        },
      } = await axios.post(create_reservation_api, {
        reservationData: {
          ...reservationData,
          date: createFormattedDateString(Date.now()),
        },
        orderData,
      });
      dispatch(setTimerExpirationTime(expirationTime));
      // history.replace(order_page_path.replace(':orderId', orderId))
      dispatch(setNewOrderDetails({}));
      history.push(reservations_path_now);
    } else {
      // history.replace(order_page_path.replace(':orderId', currentOrder?.order_id))
      history.push(reservations_path_now);
    }
  } catch (err) {
    // dispatch(setErrors(err, () => createReservation(reservationData, orderData, history)))
    // history.push(home_path)
  } finally {
    dispatch(setLoader(false));
  }
};

export const getPaymentMethodsForUser = () => async (dispatch) => {
  try {
    const paymentMethods = await axios.get(get_payment_methods_api);
    dispatch(setPaymentMethodsForUser(paymentMethods.data));
  } catch (err) {
    dispatch(setErrors(err), () => getPaymentMethodsForUser());
  }
};

export const setPaymentMethodsForUser = (payload) => (dispatch) => {
  dispatch({ type: SET_PAYMENT_METHODS_FOR_USER, payload });
};

export const createPaymentMethodForUser = (data) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    await axios.post(payment_method_api, data);
  } catch (err) {
    dispatch(setErrors(err, () => createPaymentMethodForUser(data)));
  } finally {
    dispatch(setLoader(false));
  }
};

export const updatePaymentMethodForUser = (token, successMsg = true) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const {
      data: { message },
    } = await axios.put(payment_method_api, { token });
    successMsg && dispatch(setToastMessage({ message: message }));
  } catch (err) {
    dispatch(setErrors(err, () => updatePaymentMethodForUser(token)));
  } finally {
    dispatch(setLoader(false));
  }
};

export const deletePaymentMethodForUser = (token) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    await axios.delete(payment_method_api, { params: { token } });
    dispatch(getPaymentMethodsForUser());
  } catch (err) {
    dispatch(setErrors(err), () => deletePaymentMethodForUser(token));
  } finally {
    dispatch(setLoader(false));
  }
};

export const editFeedback = (tip, feedback, orderId, payForUsers) => async (dispatch) => {
  try {
    await axios.put(edit_feedback_api.replace(":orderId", orderId), { tip, feedback });
    dispatch(setFeedback({ tip, feedback }));
    dispatch(getCheckoutInfo(orderId, payForUsers));
  } catch (err) {
    dispatch(setErrors(err), () => editFeedback(tip, feedback, orderId, payForUsers));
  }
};

export const setFeedback = (payload) => (dispatch) => {
  dispatch({ type: SET_FEEDBACK, payload });
};

export const editRating = (orderId, ratingStars, ratingDescription) => async (dispatch) => {
  try {
    await axios.put(edit_rating_api.replace(":orderId", orderId), {
      ratingStars,
      ratingDescription,
    });
    setRating({ ratingStars, ratingDescription });
  } catch (err) {
    dispatch(setErrors(err.response ?? err));
  }
};

export const setRating = (payload) => (dispatch) => {
  dispatch({ type: SET_USER_RATING, payload });
};

export const applePayLogs = (logs) => async (dispatch) => {
  try {
    await axios.post("/api/orders/apple-pay/logs", { logs });
  } catch (err) {
    dispatch(setErrors(err));
  }
};

export const getItemInfo = (itemId, orderId) => async (dispatch) => {
  try {
    const data = await axios.get(item_info_api.replace(":orderId", orderId).replace(":itemId", itemId));

    return data.data.itemInfo;
  } catch (err) {
    dispatch(setErrors(err), () => getItemInfo(itemId));
  }
};

export const setBookedReservationReturn = (payload) => (dispatch) => {
  dispatch({ type: SET_BOOKED_RESERVATION_RETURN, payload });
};

export const setClearReservationOrders = () => (dispatch) => {
  dispatch({ type: SET_CLEAR_RESERVATION_ORDERS });
};

export const setTableNumber = (payload) => (dispatch) => {
  dispatch({ type: SET_TABLE_NUMBER, payload });
};
export const setHasNewOrder = (payload) => (dispatch) => {
  dispatch({ type: SET_HAS_NEW_ORDER, payload });
};

export const setShowAddItemModal = (payload) => (dispatch) => {
  dispatch({ type: SET_SHOW_ADD_ITEM_MODAL, payload });
};

export const setPendingLoyaltyPoints = (orderId, discount, cb) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    await axios.put(orders_loyalty_program_pending_api, { orderId, discountAmount: +discount });
    if (cb) cb();
    dispatch(setLoader(false));
  } catch (err) {
    dispatch(setLoader(false));
    dispatch(setErrors(err), () => setPendingLoyaltyPoints(orderId, discount, cb));
  }
};

export const updateAppliedLoyaltyPoints = (orderingProviderId, orderId, orderExternalId, checkExternalId, cb) => async (
  dispatch,
) => {
  try {
    dispatch(setLoader(true));
    await axios.post(orders_loyalty_program_applied_api, {
      orderingProviderId,
      orderId,
      orderExternalId,
      checkExternalId,
    });
    if (cb) cb();
    dispatch(setLoader(false));
  } catch (err) {
    dispatch(setLoader(false));
    dispatch(setErrors(err), () =>
      setPendingLoyaltyPoints(orderingProviderId, orderId, orderExternalId, checkExternalId, cb),
    );

    // Refresh checkout, so the user won't be stuck if someone has already applied a discount
    dispatch(setIsPay(false));
  }
};

export const setUserTransactionLoyaltyProgram = (payload) => (dispatch) => {
  dispatch({ type: SET_USER_TRANSACTION_LOYALTY_PROGRAM, payload });
};

export const getUserTransactionLoyaltyProgram = () => async (dispatch) => {
  try {
    const { data } = await axios.get(user_transaction_loyalty_program);
    dispatch(setUserTransactionsLoyaltyProgram(data.result));
  } catch (err) {
    dispatch(setErrors(err), () => getUserTransactionLoyaltyProgram());
  }
};

export const setUserTransactionsLoyaltyProgram = (payload) => (dispatch) => {
  dispatch({ type: SET_USER_TRANSACTIONS_LOYALTY_PROGRAM, payload });
};

export const getUserLoyaltyProgram = (userId) => async (dispatch) => {
  try {
    const { data } = await axios.get(user_loyalty_program.replace(":userId", userId));
    dispatch(setUserLoyaltyProgram(data.result));
  } catch (err) {
    dispatch(setErrors(err), () => getUserLoyaltyProgram(userId));
  }
};

export const getUsersLoyaltyProgramOnOrder = (userIds, orderId) => async (dispatch) => {
  try {
    const { data } = await axios.get(users_loyalty_program_on_order.replace(":orderId", orderId));
    dispatch(setUsersLoyaltyProgramOnOrder(data.result));
  } catch (err) {
    dispatch(setErrors(err), () => getUsersLoyaltyProgramOnOrder(userIds, orderId));
  }
};

export const setUserLoyaltyProgram = (payload) => (dispatch) => {
  dispatch({ type: SET_USER_LOYALTY_PROGRAM, payload });
};

export const setAppliedLoyaltyPoints = (payload) => (dispatch) => {
  dispatch({ type: SET_APPLIED_LOYALTY_POINTS, payload });
};

export const setUserLoyaltyProgramAccumulatedPoints = (payload) => (dispatch) => {
  dispatch({ type: SET_USER_LOYALTY_PROGRAM_ACCUMULATED_POINTS, payload });
};

export const setUsersLoyaltyProgramOnOrder = (payload) => (dispatch) => {
  dispatch({ type: SET_USERS_LOYALTY_PROGRAM_ON_ORDER, payload });
};

export const setLoyaltyOnCurrentRestaurant = (payload) => (dispatch) => {
  dispatch({ type: SET_LOYALTY_ON_CURRENT_RESTAURANT, payload });
};

export const setLoyaltyProcess = (payload) => (dispatch) => {
  dispatch({ type: SET_LOYALTY_PROCESS, payload });
};

export const setUserLoyaltyStep = (payload) => (dispatch) => {
  dispatch({ type: SET_USER_LOYALTY_STEP, payload });
};

export const setAllGuestSelectedLoyaltyOption = (payload) => (dispatch) => {
  dispatch({ type: SET_ALL_GUEST_SELECTED_LOYALTY_OPTION, payload });
};

export const setChooseIfGuestWantToUseCreditsModal = (payload) => (dispatch) => {
  dispatch({ type: SET_CHOOSE_IF_GUEST_WANT_TO_USE_CREDITS_MODAL, payload });
};
