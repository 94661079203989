import { Switch, Typography } from "@mui/material";
import classNames from "classnames";
import isEmpty from "lodash.isempty";
import React from "react";
import { ExpandLessIcon200, ExpandMoreIcon200 } from "../../assets/svgs-google/GoogleIcons";
import Image from "../../components/common/Image";
import { CustomDrawer } from "../../components/common/previewComponents";
import MenuItemBadge from "../../components/orders/MenuItemBadge";
import MenuOrders from "../../components/ordersRounds/MenuOrders";
import { colorsEnum } from "../../enums/colorsEnum";
import { fontFamiliesEnum } from "../../enums/fontFamiliesEnum";
import useFloatingIslandActions from "../../hooks/useFloatingIslandActions";
import MenuSectionListApproved from "./MenuSectionListApproved";
import MenuSectionsStepper from "./MenuSectionsStepper";

const MenuListApproved = ({
  restaurantMenu,
  orderId,
  restaurantLogo,
  isMobile,
  isMenuOpened,
  menuDrawerOpened,
  menuOrdersDrawerOpened,
  drawerRef,
  menuSteps,
  menuStepsUser,
  isMenuStepsFeatureEnabled,
  activeMenuId,
  activeMenuName,
  activeMenuSectionId,
  updateSectionAndItemsBasedOnStep,
  activeMenuSectionName,
  menuItemsQuantity,
  sectionItemsQuantity,
  selectedMenuAndSectionsByStep,
  onReservation,
  onBooking,
  handleIsMenuDrawerOpened,
  handleIsMenuOrdersDrawerOpened,
  handleChangeMenu,
  handleChangeMenuSection,
  handleBackToBooking,
  handleIsMenuStepsFeatureEnabled,
  menuItemDetails,
  menuItemRecommendations,
  onMenuItemDetailsModal,
  onAddRecommendedMenuItemsModal,
  onAddEditDeleteItemModal,
  onFullPageModal,
  history,
}) => {
  // Return to this to fix quantity bug on steps feature for menus where sections are excluded..

  // const updatedMenuItemsQuantity = JSON.parse(JSON.stringify(menuItemsQuantity));
  // useEffect(() => {
  //     // Create a deep copy of menuItemsQuantity

  //     // Update the quantity based on active step and sections if isMenuStepsFeatureEnabled is true
  //     if (isMenuStepsFeatureEnabled && selectedMenuAndSectionsByStep) {
  //     updatedMenuItemsQuantity.forEach(item => {
  //             const matchingSections = selectedMenuAndSectionsByStep
  //                 .filter(menu => menu.menu_id === item.menuId)
  //                 .map(menu => menu.menu_sections.map(section => section.section_id))
  //                 // .flatMap(menu => menu.menu_sections.map(section => section.section_id));

  //                 console.log(sectionItemsQuantity.filter(section => !matchingSections.includes(+section.sectionId)))

  //             const missingSections = sectionItemsQuantity.filter(section => {
  //                 return !matchingSections.includes(section.sectionId);
  //             });

  //             missingSections.forEach(missingSection => {
  //                 item.quantity -= missingSection.quantity; // Decrease the quantity
  //             });

  //             item.quantity = Math.max(item.quantity, 0);
  //         });
  //     }
  //     console.log(updatedMenuItemsQuantity)

  // }, [isMenuStepsFeatureEnabled, sectionItemsQuantity, menuItemsQuantity, selectedMenuAndSectionsByStep])
  const {
    toggleMenuDrawer,
    toggleMenuOrderDrawer,
    createButtonPortal,
    createElementPortal,
    backToOrderShortcut,
    backToBooking,
    DOMslot1,
    DOMslot2,
    DOMslot3,
    DOMslot4,
  } = useFloatingIslandActions();

  // useEffec(() => {
  //     console.log(  isEmpty(menuItemDetails),
  //     isEmpty(menuItemRecommendations),
  //     !onMenuItemDetailsModal,
  //     !onAddRecommendedMenuItemsModal,
  //     !onAddEditDeleteItemModal,
  //     !onFullPageModal,
  //     )
  // },[menuItemDetails, menuItemRecommendations, onMenuItemDetailsModal, onAddRecommendedMenuItemsModal, onAddEditDeleteItemModal, onFullPageModal])

  const restaurantMenuContainer = (
    <div style={DOMslot1 ? { overflow: "scroll", minHeight: "fit-content", margin: "0 0 7rem" } : {}}>
      {isMobile ? (
        <div className="restaurant-logo-wrapper" style={{ margin: "0.5rem 0" }}>
          <Image
            width={"240px"}
            height={"160px"}
            restaurant
            src={restaurantLogo}
            alt="restaurant-logo"
            style={{ objectFit: "contain" }}
          />
        </div>
      ) : null}

      {menuSteps && menuStepsUser && !isEmpty(restaurantMenu) ? (
        <div style={{ display: "flex", alignItems: "center", margin: "0 0 1rem" }}>
          <Switch
            checked={isMenuStepsFeatureEnabled || false}
            onChange={handleIsMenuStepsFeatureEnabled}
            sx={{
              "& .MuiSwitch-thumb": {
                backgroundColor: colorsEnum.navyBlue,
              },
              "& .MuiSwitch-track": {
                backgroundColor: colorsEnum.navyBlue,
              },
            }}
          />
          <Typography sx={{ fontFamily: fontFamiliesEnum.cormorant }}>
            {`${isMenuStepsFeatureEnabled ? "Return to classic" : "Take me to guided"} dining experience`}
          </Typography>
        </div>
      ) : null}
      {!isMobile &&
        isEmpty(menuItemRecommendations) &&
        !onMenuItemDetailsModal &&
        isEmpty(menuItemDetails) &&
        !onAddRecommendedMenuItemsModal &&
        !onAddEditDeleteItemModal &&
        !onFullPageModal &&
        (!isMenuStepsFeatureEnabled ? (
          <>
            {onReservation && !onBooking && backToOrderShortcut(DOMslot1)}
            {onReservation && onBooking && createButtonPortal(backToBooking(handleBackToBooking), DOMslot1)}
          </>
        ) : (
          <>
            {createElementPortal(<MenuSectionsStepper />, DOMslot1)}
            {onReservation && !onBooking && backToOrderShortcut(DOMslot3)}
            {onReservation && onBooking && createButtonPortal(backToBooking(handleBackToBooking), DOMslot3)}
          </>
        ))}

      <div style={{ marginBottom: "4em" }}>
        {restaurantMenu?.map((menu, idx) => {
          if (isEmpty(menu)) return null;
          return (
            <div key={menu.menu_id ?? idx} style={{ margin: "0 0.5rem" }}>
              <div
                onClick={() => handleChangeMenu(menu?.menu_id, menu?.menu_name)}
                className="restaurant-menu-container"
              >
                <div className="menu-heading-container">
                  <div className="menu-heading-wrapper" style={{ position: "relative" }}>
                    <h2
                      className={classNames("restaurant-menu-heading", {
                        active: isMenuOpened && activeMenuId === menu?.menu_id,
                      })}
                    >
                      {menu.menu_name}
                    </h2>
                    {onReservation && !!(menu.menu_id !== activeMenuId || !isMenuOpened)
                      ? menuItemsQuantity?.map(
                          (item, idx) =>
                            menu.menu_id === item.menuId && (
                              <MenuItemBadge key={menu.menu_id ?? idx} number={item.quantity} />
                            ),
                        )
                      : null}
                  </div>
                  <div className="side-menu-menu-icon">
                    {activeMenuId === menu?.menu_id && isMenuOpened
                      ? ExpandLessIcon200(32, "#020083 ")
                      : ExpandMoreIcon200(32, "#D5D5D5")}
                  </div>
                </div>
              </div>
              <div className="side-menu-item-separator" />

              {!isEmpty(menu?.menu_sections) ? (
                <MenuSectionListApproved
                  isMenuOpened={isMenuOpened}
                  activeMenuId={activeMenuId}
                  activeMenuSectionId={activeMenuSectionId}
                  menuSections={menu?.menu_sections}
                  sectionItemsQuantity={sectionItemsQuantity}
                  selectedMenuAndSectionsByStep={selectedMenuAndSectionsByStep}
                  isMenuStepsFeatureEnabled={isMenuStepsFeatureEnabled}
                  menuId={menu?.menu_id}
                  onReservation={onReservation}
                  handleChangeMenuSection={handleChangeMenuSection}
                />
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );

  // console.log(
  //     !!activeMenuName,
  //     isEmpty(menuItemRecommendations),
  //     !onMenuItemDetailsModal,
  //     isEmpty(menuItemDetails),
  //     !onAddRecommendedMenuItemsModal,
  //     !onAddEditDeleteItemModal,
  //     !onFullPageModal,
  //     isMenuStepsFeatureEnabled,
  //     menuDrawerOpened,
  //     menuOrdersDrawerOpened,
  //     )

  const restaurantMenuContainerMobile = (
    <div>
      {!!activeMenuName && !!activeMenuSectionName ? (
        <div style={{ padding: "0", textAlign: "center" }}>
          <div style={{ display: "flex", justifyContent: "center", margin: "0.5rem 0 1rem" }}>
            <h4>{activeMenuName + " / " + activeMenuSectionName}</h4>
          </div>
        </div>
      ) : null}
      {!!activeMenuName && (
        <>
          {/* <div className='custom-btn-wrapper'>
                            <CustomButtonMenu onClick={handleIsMenuDrawerOpened}
                                icon
                                text={activeMenuName + " / " + activeMenuSectionName}
                                textClassName="voila-primary-menu-btn-text"
                                textStyle={{ paddingLeft: "0.5rem" }}
                            />
                        </div> */}
          {isEmpty(menuItemRecommendations) &&
            !onMenuItemDetailsModal &&
            isEmpty(menuItemDetails) &&
            !onAddRecommendedMenuItemsModal &&
            !onAddEditDeleteItemModal &&
            !onFullPageModal &&
            (!isMenuStepsFeatureEnabled ? (
              <>
                {createButtonPortal(toggleMenuDrawer, DOMslot1)}
                {onReservation && !onBooking && backToOrderShortcut(DOMslot2)}
                {onReservation && onBooking && createButtonPortal(backToBooking(handleBackToBooking), DOMslot2)}
                {onReservation && createButtonPortal(toggleMenuOrderDrawer, DOMslot3)}
                {/* {menuOrdersDrawerOpened && createButtonPortal(sendToKitchen(() => { }), DOMslot4)} */}
              </>
            ) : (
              <>
                {!!menuDrawerOpened ? (
                  <>{createElementPortal(<MenuSectionsStepper />, DOMslot1)}</>
                ) : (
                  <>{createButtonPortal(toggleMenuDrawer, DOMslot1)}</>
                )}
                {onReservation && onBooking && createButtonPortal(backToBooking(handleBackToBooking), DOMslot4)}
                {onReservation && createButtonPortal(toggleMenuOrderDrawer, DOMslot3)}
                {onReservation && !onBooking && backToOrderShortcut(DOMslot4)}
                {/* {menuOrdersDrawerOpened && createButtonPortal(sendToKitchen(() => { }), DOMslot5)} */}
              </>
            ))

            // contains bug on full menu page toggleMenuDrawer should be in slot 1 istead of slot 2
            // and workaround for the transition of the content in the slots
            // both toggleMenuDrawer and backToBooking are in slot 1

            // !isMenuStepsFeatureEnabled ? (
            //     <>
            //         {onReservation && !onBooking && backToOrderShortcut(DOMslot1)}
            //         {onReservation && onBooking && createButtonPortal(backToBooking(handleBackToBooking), DOMslot1)}
            //         {createButtonPortal(toggleMenuDrawer, DOMslot2)}

            //         {onReservation && createButtonPortal(toggleMenuOrderDrawer, DOMslot3)}
            //         {/* {menuOrdersDrawerOpened && createButtonPortal(sendToKitchen(() => { }), DOMslot4)} */}
            //     </>
            // ) : (
            //     <>
            //         {onReservation && onBooking && createButtonPortal(backToBooking(handleBackToBooking), DOMslot1)}

            //         {!!menuDrawerOpened ?
            //             <>
            //                 {createElementPortal(<MenuSectionsStepper />, DOMslot1)}
            //             </> :
            //             <>
            //                 {createButtonPortal(toggleMenuDrawer, DOMslot1)}
            //             </>
            //         }

            //         {onReservation && createButtonPortal(toggleMenuOrderDrawer, DOMslot3)}
            //         {onReservation && !onBooking && backToOrderShortcut(!menuDrawerOpened ? DOMslot1 : DOMslot4)}
            //         {/* {menuOrdersDrawerOpened && createButtonPortal(sendToKitchen(() => { }), DOMslot5)} */}
            //     </>
            // )
          }
        </>
      )}
      {/* <SwipeableEdgeDrawerContent /> */}
      <CustomDrawer
        anchor="left"
        // disableDiscovery
        // disableBackdropTransition={false}
        zIndex={1310}
        open={menuDrawerOpened}
        toggleDrawer={handleIsMenuDrawerOpened}
        paperWidth={80}
        content={restaurantMenuContainer}
      />
      <CustomDrawer
        anchor="right"
        zIndex={1310}
        open={menuOrdersDrawerOpened}
        toggleDrawer={handleIsMenuOrdersDrawerOpened}
        paperWidth={80}
        content={<MenuOrders orderId={orderId} restaurantLogo={restaurantLogo} />}
      />
    </div>
  );

  return (
    <div className="full-menu-container">
      {/* {activeMenuName && activeMenuSectionName ?
                <div style={{ height: "3rem", display:"flex", justifyContent:"center", marginRight:"0.5rem" }}>
                    <h4>
                        {activeMenuName + " / " + activeMenuSectionName}
                    </h4>
                </div> : null
            } */}
      {!isMobile ? restaurantMenuContainer : restaurantMenuContainerMobile}
    </div>
  );
};

export default MenuListApproved;
