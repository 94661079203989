import { combineReducers } from "redux";
import errors from "./reducers/errorsReducer";
import feedback from "./reducers/feedbackReducer";
import restaurants from "./reducers/restaurantsReducer";
import restaurant from "../pages/restaurant/services/reducers";
import auth from "../pages/auth/services/reducers";
import booking from "../pages/book/services/reducers";
import orders from "../pages/order/services/reducer";
import passwordReset from "../pages/auth/resetPassword/services/reducers";
import navigation from "../components/navigation/services/reducer";
import user from "./reducers/usersReducer";
import menu from "../pages/menu/services/reducers";
import versionConfig from "./reducers/versionConfigReducer";

export default combineReducers({
  errors,
  feedback,
  restaurants,
  restaurant,
  auth,
  booking,
  orders,
  passwordReset,
  navigation,
  user,
  menu,
  versionConfig,
});
