import {
  SET_RESTAURANTS,
  CLEAR_RESTAURANT_RESERVATION,
  SET_RESTAURANT,
  SET_CODE_LIST_VALUES,
} from "../../config/actionTypes";

const initialState = {
  restaurants: [],
  restaurant: {},
  types: [],
  cities: [],
  menuKeywords: [],
};

const restaurantsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RESTAURANTS:
      return {
        ...state,
        restaurants: action.payload,
      };
    case SET_RESTAURANT:
      return { ...state, restaurant: action.payload };

    case CLEAR_RESTAURANT_RESERVATION:
      return {
        ...state,
        restaurants: state.restaurants.map((restaurant) => {
          if (Object.values(restaurant.reservation).length > 0) {
            return { ...restaurant, reservation: {} };
          } else {
            return { ...restaurant };
          }
        }),
      };
    case SET_CODE_LIST_VALUES:
      return {
        ...state,
        types: action.payload?.types,
        cities: action.payload?.cities,
        menuKeywords: action.payload.menuKeywords,
      };
    default:
      return state;
  }
};

export default restaurantsReducer;
