import axios from "axios";
import jwtDecode from "jwt-decode";
import {
  deactivate_account_api,
  get_access_token_api,
  login_api,
  login_with_apple_api,
  login_with_facebook_api,
  login_with_google_api,
  logout_api,
  register_anonymously_api,
  register_api,
  register_redirect_api,
  reset_password_api,
  reset_password_redirect_api,
  user_api,
  user_name_api,
  user_password_api,
} from "../../../config/api_endpoints";
import {
  setActiveModal,
  setLoader,
  setRegisterRedirectResponse,
  setResponseMessage,
  setToastMessage,
} from "../../../store/actions/feedbackActions";

import { SET_USER } from "../../../config/actionTypes";
import { setErrors } from "../../../store/actions/errorsActions";
import { clearRestaurantReservation } from "../../../store/actions/restaurantsActions";
import { getUser, setNewUser, setUser } from "../../../store/actions/usersActions";
import setAuthToken from "../../../utils/setAuthToken";
import { handleReservationInvite } from "../../book/services/actions";
import { setClearReservationOrders } from "../../order/services/actions";
import { setCurrentReservation } from "../../reservations/services/actions";
import reconnectSocket from "../../../handlers/reconnectSocket";
import { home_path } from "../../../config/pages_paths";

export const register = (userData) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const result = await axios.post(register_api, userData);
    dispatch(setResponseMessage("Confirmation link sent"));
    return result;
  } catch (err) {
    dispatch(setToastMessage(err.response.data?.message));
    dispatch(setErrors(err));
  } finally {
    dispatch(setLoader(false));
  }
};

export const login = ({ email, password, reservation: reservationToken = "", cb, cbParams }) => async (dispatch) => {
  try {
    dispatch(setLoader(true));

    const {
      data: { accessToken, refreshToken, reservation },
    } = await axios.post(login_api, {
      email,
      password,
      reservation: reservationToken,
    });

    dispatch(authUserWithToken(accessToken, refreshToken));
    // dispatch(getUser())
    reservation && dispatch(handleReservationInvite(reservation));

    if (cb) cb(cbParams);

    return accessToken;
  } catch (err) {
    dispatch(setErrors(err));
  } finally {
    dispatch(setLoader(false));
  }
};

export const loginWithFacebook = (access_token, reservationToken = "") => async (dispatch) => {
  try {
    const {
      data: { accessToken, refreshToken, reservation },
    } = await axios.post(login_with_facebook_api, {
      access_token,
      reservation: reservationToken,
    });
    dispatch(authUserWithToken(accessToken, refreshToken));
    reservation && dispatch(handleReservationInvite(reservation));
  } catch (err) {
    dispatch(setErrors(err));
  }
};

export const loginWithGoogle = (id_token, reservationToken = "") => async (dispatch) => {
  try {
    const {
      data: { accessToken, refreshToken, reservation },
    } = await axios.post(login_with_google_api, {
      id_token,
      reservation: reservationToken,
    });
    dispatch(authUserWithToken(accessToken, refreshToken));
    reservation && dispatch(handleReservationInvite(reservation));
  } catch (err) {
    dispatch(setErrors(err));
  }
};

export const loginWithApple = (code, reservationToken = "") => async (dispatch) => {
  try {
    const {
      data: { accessToken, refreshToken, reservation },
    } = await axios.post(login_with_apple_api, {
      code,
      grant_type: "authorization_code",
      reservation: reservationToken,
    });
    dispatch(authUserWithToken(accessToken, refreshToken));
    reservation && dispatch(handleReservationInvite(reservation));
  } catch (err) {
    dispatch(setErrors(err));
  }
};

export const passwordResetRequest = (email) => async (dispatch) => {
  try {
    await axios.post(reset_password_api, { email });
    dispatch(setResponseMessage("Password reset message sent"));
  } catch (err) {
    dispatch(setErrors(err));
  }
};

export const registerRedirect = (token, history) => async (dispatch) => {
  try {
    const axiosInstance = axios.create();
    axiosInstance.defaults.headers["Authorization"] = token;

    const {
      data: { accessToken, refreshToken, reservation },
    } = await axiosInstance.get(register_redirect_api);
    if (!accessToken) {
      return dispatch(setRegisterRedirectResponse({ isAlreadyRegistered: true }));
    }
    reservation && dispatch(handleReservationInvite(reservation));
    dispatch(authUserWithToken(accessToken, refreshToken));
    dispatch(setRegisterRedirectResponse({ userRegistered: true }));
    dispatch(getUser());
    dispatch(setToastMessage({ message: "Your account has been confirmed", severity: "info" }));
  } catch (err) {
    dispatch(setErrors(err));
  }
};

export const registerAnonymously = (visitorData, restaurantId, tableNumber, cb, cbParams) => async (dispatch) => {
  try {
    const {
      data: { accessToken, refreshToken },
    } = await axios.post(register_anonymously_api, { visitorData, restaurantId, tableNumber });

    if (!accessToken) {
      return dispatch(setRegisterRedirectResponse({ isAlreadyRegistered: true }));
    }

    dispatch(authUserWithToken(accessToken, refreshToken));
    dispatch(setRegisterRedirectResponse({ userRegistered: true }));
    dispatch(getUser());

    if (cb) cb(cbParams);
  } catch (err) {
    dispatch(setErrors(err, () => registerAnonymously(visitorData, restaurantId, tableNumber, cb, cbParams)));
  }
};

export const authUserWithToken = (accessToken, refreshToken) => (dispatch, getState) => {
  try {
    const {
      booking: { currentOrder },
    } = getState();

    setAuthToken(accessToken);
    const decoded = jwtDecode(accessToken.split(" ")[1]);
    localStorage.setItem("jwtToken", accessToken);
    refreshToken && localStorage.setItem("jwtRefreshToken", refreshToken);
    dispatch(setCurrentUser(decoded));
    dispatch(setActiveModal(""));
    dispatch(getUser());

    reconnectSocket(currentOrder, decoded.id, null, dispatch);
  } catch (err) {
    dispatch(setErrors(err));
  }
};

export const getAccessToken = (cb) => async (dispatch) => {
  try {
    const {
      data: { accessToken },
    } = await axios.get(get_access_token_api);
    dispatch(authUserWithToken(accessToken));
    if (cb) dispatch(cb());
  } catch (err) {
    dispatch(setErrors(err));
  }
};

export const logoutUser = () => (dispatch) => {
  try {
    const refreshToken = localStorage.getItem("jwtRefreshToken");
    axios.delete(logout_api.replace(":refreshToken", refreshToken));

    setAuthToken("");
    dispatch(setCurrentUser({}));
    dispatch(setUser({}));
    dispatch(setNewUser({}));
    // Clear User Reservation
    dispatch(setCurrentReservation(null));
    dispatch(clearRestaurantReservation());
    // Clear User Reservation Orders
    dispatch(setClearReservationOrders());

    localStorage.removeItem("jwtToken");
    localStorage.removeItem("jwtRefreshToken");
  } catch (err) {
    dispatch(setToastMessage({ message: "Logout failed", severity: "info" }));
  }
};

export const setCurrentUser = (payload) => (dispatch) => {
  dispatch({ type: SET_USER, payload });
};

export const updateUser = (data) => async (dispatch) => {
  try {
    setLoader(true);
    const result = await axios.post(user_api, data);

    dispatch(setToastMessage({ message: "Profile successfully updated", severity: "info" }));
    dispatch(getUser());
    return result;
  } catch (err) {
    dispatch(setToastMessage({ message: "Failed to update profile", severity: "info" }));
  } finally {
    setLoader(false);
  }
};

export const updateUserPassword = (passwords, is_reset, history) => async (dispatch) => {
  try {
    const { data } = await axios.post(is_reset ? reset_password_redirect_api : user_password_api, passwords);

    if (data) {
      dispatch(setToastMessage({ message: "Password successfully updated", serverity: "info" }));
      dispatch(getUser());
      history.push(home_path);
    }
  } catch (err) {
    dispatch(setErrors(err, () => updateUser(passwords)));
  }
};

export const updateUserName = (name, order_id) => async (dispatch) => {
  try {
    const { data } = await axios.patch(user_name_api, name, order_id);
    if (data) {
      await dispatch(setUser(data));
    }
  } catch (err) {
    dispatch(setErrors(err));
  }
};

export const deactivateAccount = (logout) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    await axios.post(deactivate_account_api);
    logout();
  } catch (err) {
    dispatch(setErrors(err, () => deactivateAccount()));
  } finally {
    dispatch(setLoader(false));
  }
};
